export const config = {
  routes: {
    brand: {
      id: 216
    },
    category: {
      hide: false
    },
    product: {
      hide: false
    }
  },
  settings: {
    storeName: 'Norrmalms Elektriska',
    siteContentId: 74
  }
};
