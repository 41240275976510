import UIDrawer from '@jetshop/ui/Modal/Drawer/Drawer';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import SiteContentContext from '../../../SiteContent/SiteContentContext';
import { theme } from '../../../Theming/Theming';
import CloseButton from '../../../ui/CloseButton';
import DrawerHeader from '../../../ui/DrawerHeader';
import Logo from '../Logo';
import { LoginWidget } from '../Util/LoginWidget';
import MobileCategories from './MobileCategories';

const StyledCloseButton = styled(CloseButton)`
  padding: 10px;
  margin: -10px;
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  padding-bottom: 30px;
`;

const MobileNavWrapper = styled('div')`
  height: 100%;
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  background: ${theme.colors.white};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${theme.mobileHeader.nav.padding};
  margin: 1rem 0;
  border: none;
`;

const LogoWrapper = styled('div')`
  padding: 10px 0;
  max-width: 100%;
  width: 100px;
  position: relative;
  left: -5px;
`;

const NavLists = styled('div')`
  padding: 0 ${theme.mobileHeader.nav.padding};
  > * {
    margin-top: 3rem;
  }
  ul {
    li {
      display: block;
      line-height: 42px;
      text-decoration: none;
      border-bottom: 1px solid ${theme.colors.border};
    }
  }
`;

/*const ChannelSelectorWrapper = styled('div')`
  padding: 0 ${theme.mobileHeader.nav.padding};
  margin-top: 2rem;
`;*/

/* const getIcon = (index) => {
  const icon =
    index === 0 ? faPhone : index === 1 ? faEnvelope : faMapMarkerAlt;
  return <FontAwesomeIcon icon={icon} />;
}; */

const MobileNav = ({
  categories,
  categoryTreeRoots,
  hideTarget,
  isOpen,
  right,
  ...rest
}) => {
  const { mobilePageList } = useContext(SiteContentContext);
  return (
    <UIDrawer isOpen={isOpen} right={right} size={360}>
      <MobileNavWrapper>
        <StyledDrawerHeader>
          <LogoWrapper>
            <Link to="/" onClick={hideTarget}>
              <Logo mobile={true} />
            </Link>
          </LogoWrapper>
          <StyledCloseButton onClick={hideTarget} />
        </StyledDrawerHeader>
        <Scroll>
          <MobileCategories
            hideTarget={hideTarget}
            categories={categoryTreeRoots}
            categoryTreeRoots={categoryTreeRoots}
            isOpen={isOpen}
          />
          <NavLists onClick={hideTarget}>
            {mobilePageList}
            <LoginWidget />
          </NavLists>
        </Scroll>
      </MobileNavWrapper>
    </UIDrawer>
  );
};
export default MobileNav;
