import CartProvider from '@jetshop/core/components/Query/CartProvider';
import { Above } from '@jetshop/ui/Breakpoints';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import React from 'react';
import CartContent from './CartContent';
import cartQuery from './CartQuery.gql';

const CartFlyout = (props) => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {(result) =>
      result.data && result.data.cart ? (
        <Above breakpoint="md">
          {(matches) => {
            const size = matches ? 420 : 360;
            return (
              <DrawerTarget id="cart-drawer">
                {(drawer) => (
                  <Drawer isOpen={drawer.isOpen} right size={size}>
                    <CartContent
                      modal={drawer}
                      result={result}
                      {...props}
                      className="drawer"
                    />
                  </Drawer>
                )}
              </DrawerTarget>
            );
          }}
          {/* Flyout is used for desktop */}
          {/*<FlyoutTarget id="cart-flyout">
            {flyout => <CartFlyoutView modal={flyout} cart={cart} {...props} className="flyout" />}
           </FlyoutTarget>*/}

          {/* Drawer is used for mobile */}
        </Above>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
