import React from 'react';
import Image from '@jetshop/ui/Image';

const Logo = ({ secondary = false, size = [400] }) => {
  let src = '/pub_images/original/logo.png';
  if (secondary) src = '/pub_images/original/logo_white.png';
  return <Image src={src} sizes={size} aspect={'300:100'} crop={false} />;
};

export default Logo;
